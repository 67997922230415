import React, { Component } from 'react';
import { navigate } from 'gatsby';
import urls from '../data/urls';
import spinner from '../images/bar-loader.gif';

const formFieldNames = Object.freeze({
  email: 'email',
  name: 'name',
  message: 'message',
  phone: 'phone',
  contactMethod: 'contactMethod',
  business: 'business',
});


class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      isWaiting: false,
    };

    this.inputRefs = Object.keys(formFieldNames).reduce((accumulator, key) => ({ ...accumulator, [key]: React.createRef() }), {});
  }

  clearInputs() {
    Object.values(this.inputRefs).forEach((inputRef) => {
      inputRef.current.value = null;
    });
  }

  handleSubmit(event) {
    // lifting out of closure because args get lost in callback
    const formElement = event.target;

    this.setState({
      isDisabled: true,
      isWaiting: true,
    }, () => {
      const email = formElement[formFieldNames.email].value;
      const name = formElement[formFieldNames.name].value;
      const message = formElement[formFieldNames.message].value;
      const phone = formElement[formFieldNames.phone].value;
      const contactMethod = formElement[formFieldNames.contactMethod].value;
      const business = formElement[formFieldNames.business].value;

      const payload = {
        email,
        name,
        message,
        phone,
        contactMethod,
        business,
      };

      fetch(urls.api.contactUs, {
        method: 'post',
        body: JSON.stringify(payload),
      }).finally(() => {
        this.setState({
          isDisabled: false,
          isWaiting: false,
        });
        this.clearInputs();
        navigate(urls.thankYou);
      });
    });

    event.preventDefault();
  }

  render() {
    const {
      isDisabled,
      isWaiting,
    } = this.state;

    return (
      <section className="contact_info_area sec_pad bg_color">
        <div className="container p-2">
          <div className="row m-0">
            <div className="w-100">
              <h2 className="f_p f_size_22 t_color3 f_600 l_height28">OPNA</h2>
              <div className="row ml-0">
                <i className="ti-map-alt my-1 mr-2"></i>
                <div>
                  <p>
                    30 Skyway Lane
                    <br />
                    Oakland, CA 94619
                  </p>
                </div>
              </div>
              <a className="row ml-0" href="tel:+15108988892">
                <i className="ti-headphone-alt my-1 mr-2"></i>
                <p>510.898.8892</p>
              </a>
              <h2 className="f_p f_size_22 t_color3 f_600 l_height28 my-4">Your Information</h2>
              <form onSubmit={(e) => this.handleSubmit(e)}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <input
                        disabled={isDisabled}
                        type="text"
                        name={formFieldNames.name}
                        placeholder="Your Name"
                        ref={this.inputRefs.name}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <input
                        disabled={isDisabled}
                        type="email"
                        name={formFieldNames.email}
                        placeholder="Your Email"
                        ref={this.inputRefs.email}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group text_box">
                      <input
                        disabled={isDisabled}
                        type="tel"
                        name={formFieldNames.phone}
                        placeholder="Best number to reach you at"
                        ref={this.inputRefs.phone}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group text_box">
                      <input
                        disabled={isDisabled}
                        type="text"
                        name={formFieldNames.business}
                        placeholder="Company"
                        ref={this.inputRefs.business}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group text_box">
                      <textarea
                        disabled={isDisabled}
                        name={formFieldNames.message}
                        cols={30}
                        rows={10}
                        placeholder="What would you like to discuss?"
                        ref={this.inputRefs.message}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group text_box">
                      <input
                        disabled={isDisabled}
                        type="text"
                        name={formFieldNames.contactMethod}
                        placeholder="How you’d like us to contact you"
                        ref={this.inputRefs.contactMethod}
                      />
                    </div>
                  </div>
                </div>
                {(() => {
                if (isWaiting) {
                    return (
                      <img
                        className="contact-loader"
                        src={spinner}
                        alt=""
                      />
                    );
                  }
                  return (<button type="submit" className="btn_three">Send Message</button>);
                })()}
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contacts;
